<template>
  <div class="row m-0 justify-content-center bg_image" :class="{uk: isUK}">
    <div class="overlay"></div>
    <div class="col-12 p-0">
      <div class="container-lg" >
        <div class="row m-0 justify-content-center">
          <div class="col-12 p-0 above_overlay">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContactInfoBackground',
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>

.bg_image {
  background-image: url(../images/contact_bg.jpg);
  color: #fff;
  font-weight: 500;
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  line-height: 1.8;
  background-repeat: no-repeat;
  position: relative;
  background-position: top;
  /* background-size: 100% 750px; */
  background-size: cover;
}
.bg_image.uk {
  background-size: cover;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  /* height: 750px; */
  height: 100%;
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient(180deg, #0A1825 0%, #00000033 100%);
  opacity: 0.89;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.uk .overlay {
  height: 100%;
}
.above_overlay {
  z-index: 2;
}
</style>
